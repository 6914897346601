<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <iframe
          title="bot"
          v-if="loaded"
          name="botFrame" id="botFrame" width="100%"
          style="height: 100vh"
          :src="iframeSrc"
      ></iframe>
    </div>
  </div>
</template>

<script>

import CryptoJS from "crypto-js";

export default {
  components: {
  },
  data() {
    return {
      plan: window.localStorage.getItem("plan"),
      loaded: false,
      iframeSrc: ''
    }
  },
  mounted() {
    this.authBot();
  },
  methods: {
    authBot(){
      this.$axios.post("/member/bot/auth", {}).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            if (body.status === 200 && body.result === 'OK') {
              this.loaded = true;
              this.iframeSrc = `${body.url}?secure_key=${body.secure_key}&userid=${body.id}`;
            } else {
              this.loaded = false;
            }
          } else if (res.data.code === 9999) {
            this.$store.dispatch("SETLOGOUT").then(() => {
              window.location.href = "/sign/login";
            });
          } else if (res.data.code === 100 || res.data.code === 101) {
            this.$alert(res.data.msg).then(() => {
              window.location.href = '/profile';
            });
          } else if (res.data.code === 102) {
            this.$alert(res.data.msg).then(() => {
              window.location.href = '/subscribe';
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
